import {axiosClient} from "./httpClient";
import {errorToast, successToast, warningToast} from "./Util";
import {DONE, QA} from "./Constants";


function reportError(err) {
    console.error(err);
    errorToast("Something went wrong, please try again");
}

export function decideTimeline(revisions) {
    return revisions > 0 ? 24 : 48;
}

export function addRevision(cardId, currentStatus, callback) {
    axiosClient.post(`/api/card/${cardId}/revision`,
        {card_id: cardId, card_status: currentStatus})
        .then(({data}) => {
            successToast(data?.success || "Card moved to revision successfully");
            callback();
        }).catch(err => reportError(err));
}

export async function updateSorting(cards) {
    if (cards.length > 0) {
        return axiosClient.post('/api/cards/updateSort', {cards})
            .then(({data}) => {
                successToast(data?.success || "Information updated successfully");
            }).catch(err => reportError(err));
    }
}

export function moveCard(cardId, target, source, callback) {
    axiosClient.post(`/api/card/${cardId}/move`, {
        card_id: cardId, card_status: source, newStatus: target
    }).then(({data}) => {
        successToast(data?.success || "Card moved successfully");
        if(callback) {
            callback();
        }
    }).catch(err => reportError(err));
}

export function moveCardSubStatus(cardId, target, source, callback, additionalData = {}, selectedFile) {

    let tempObj = {};
    
    if (additionalData.done_link && additionalData.done_link.url !== "") {
        tempObj.done_link = additionalData.done_link;
    }

    if (additionalData.doneLinkUrlArray && additionalData.doneLinkUrlArray.length > 0) {
        additionalData.doneLinkUrlArray = additionalData.doneLinkUrlArray.filter(d => d.url !== "");
        tempObj.done_link = [additionalData.done_link, ...additionalData.doneLinkUrlArray];
    }

    axiosClient.post(`/api/card/${cardId}/moveCardSub`, {
        card_id: cardId, status: target, from: source, srtFile: selectedFile, ...tempObj
    }).then(response => {
        if(response.status !== 204) {
            successToast(response.data?.message || "Card moved successfully");
        } else {
            warningToast(response.data?.message || "Not allowed");
        }
        if(callback) {
            callback();
        }
    }).catch(err => {
        if ( err?.response?.data?.message && err?.response?.data?.message !== "") {
            errorToast(err.response.data.message);
        } else {
            reportError(err);
        }
    });
}

export function addCardTimeline(cardId, currentStatus, timeline, callback) {
    axiosClient.post(`/api/card/${cardId}/timeline`,
    {card_id: cardId, timeline, card_status: currentStatus})
        .then(({data}) => {
            successToast(data?.success || "Card moved successfully");
            callback();
        }).catch(err => reportError(err));
}

export function markAsDone(cardId, cardStatus, doneLink="", callback, content_id = "", doneLinkUrlArray=[]) {
    if(doneLinkUrlArray && doneLinkUrlArray.length > 0) {
        doneLink = [doneLink,...doneLinkUrlArray];
    }
    axiosClient.post(`/api/card/${cardId}/done`, {
        card_id: cardId, card_status: cardStatus, newStatus: DONE, done_link: doneLink,content_id
    }).then(({data}) => {
        successToast(data?.success || "Card moved successfully");
        if(callback)
        {
            callback();
        }
    }).catch(err => {
        // reportError(err);
        err?.response?.data?.message ? errorToast(err.response.data.message) : reportError(err);
    });
}

export function markYtCardAsCompleted (cardId, target, source, refresh) {
    axiosClient.post(`/api/ythelper/card/${cardId}/markComplete`,{
        card_id: cardId, target,source,status: 1, buttonText: "Mark As Done"
    }).then(({data})=>{
        successToast(data?.success || "Card moved successfully");
        if (refresh) {
            refresh();
        }
    }).catch(err=> reportError(err));
}

export function moveToQa(cardId, cardStatus, doneLink="", callback, content_id = "", doneLinkUrlArray=[]) {
    if(doneLinkUrlArray && doneLinkUrlArray.length > 0) {
        doneLink = [doneLink, ...doneLinkUrlArray];
    }
    axiosClient.post(`/api/card/${cardId}/moveToQa`, {
        card_id: cardId, card_status: cardStatus, newStatus: QA, done_video_link: doneLink, content_id
    }).then(({data}) => {
        successToast(data?.success || "Card moved successfully");
        if(callback)
        {
            callback();
        }
    }).catch(err => reportError(err));
}

export function moveYtHelperCardInProgress(cardId, target, source,refresh)
{
    axiosClient.post(`/api/ythelper/card/${cardId}/move`,{
        card_id: cardId, target,source
    }).then(({data})=>{
        successToast(data?.success || "Card moved successfully");
        if(refresh)
        {
            refresh();
        }
        // setTimeout(()=>{refresh()},2000);
    }).catch(err=> reportError(err));
}

export function moveYtHelperCardInDone(cardId, target, source,thumbnailsLink,videoDescription,snippetLink,unlistedLink,refresh)
{
    axiosClient.post(`/api/ythelper/card/${cardId}/done`,{
        card_id: cardId, target,source,thumbnailsLink,videoDescription,snippetLink,unlistedLink
    }).then(({data})=>{
        successToast(data?.success || "Card moved successfully");
        if(refresh)
        {
            refresh();
        }
        // setTimeout(()=>{refresh()},2000);
    }).catch(err=> reportError(err));
}

export function moveYtCard(cardId, target, source, callback) {
    axiosClient.post(`/api/ythelper/card/${cardId}/internalMove`, {
        card_id: cardId, card_status: source, newStatus: target
    }).then(({data}) => {
        successToast(data?.success || "Card moved successfully");
        if(callback) {
            // callback();
        }
    }).catch(err => reportError(err));
}

export function moveYtHelperCardInDoneForSkip(cardId, target, source,refresh)
{
    axiosClient.post(`/api/ythelper/card/${cardId}/doneSkip`,{
        card_id: cardId, target,source, skip:true,
    }).then(({data})=>{
        successToast(data?.success || "Card moved successfully");
        if (refresh) {
            refresh();
        }
        // setTimeout(()=>{refresh()},2000);
    }).catch(err=> reportError(err));
}


export function updateCardTitle(cardId, oldTitle, card_title) {
    if (oldTitle === card_title) {
        warningToast("No changes made to the title");
        return;
    }
    return axiosClient.put(`/api/card/${cardId}/updateCardDetail`, {
        oldTitle,
        card_title,
    })
        .then((res) => {
            successToast("Title updated successfully");
            return res.data; // Return response if needed
        })
        .catch((err) => {
            console.error(err);
            errorToast("Failed to update title");
            throw err; // Throw error if you want to handle it in the calling function
        });
}
